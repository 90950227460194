import Aos from 'aos';
import initPopups from './popup';
var documentReady = function () {
    initPopups();

    Aos.init({
        once: true,
    });
};

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    documentReady();
} else {
    document.addEventListener('DOMContentLoaded', documentReady);
}
