import { getCookie, setCookie } from './helpers/cookies';

class Popup {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.closeEl = this.root.querySelector('[data-popup-close]');

        const homeVisited = getCookie('mda_newsletter_home_popup_set');

        if (!homeVisited) {
            setCookie('mda_newsletter_home_popup_set', true, 180);
        }

        return true;
    }

    init() {
        this.setCloseButton();
    }

    setCloseButton() {
        if (!this.closeEl) return;

        /*window.addEventListener('click', e => {
            if (!this.root.contains(e.target)) this.onClose();
        });*/

        this.closeEl.addEventListener('click', this.onClose.bind(this));
    }

    onClose() {
        this.root.remove();
    }
}

const initPopups = () => {
    const popups = document.querySelectorAll('[data-popup]');
    if (!popups) return;

    popups.forEach(item => new Popup(item));
};

export default initPopups;
